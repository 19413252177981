import { graphql, useStaticQuery } from 'gatsby'

export const useForms = () => {
  const data = useStaticQuery(graphql`
    query {
      forms: sanityForms {
        categories
        career
        teams
      }
    }
  `)

  const { forms } = data || {}
  return forms
}
