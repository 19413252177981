import React, { useState } from 'react'
import { useForms } from '../hooks/useForms'
import { ContactButton, Close } from './Icons'
import { useMedia } from 'react-use'

const encode = data => {
	return Object.keys(data)
		.map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
		.join('&')
}

export const ContactForm = ({ hideModal }) => {
	const isWide = useMedia('(min-width: 1536px)')
	const { categories } = useForms();
	const [value, setValue] = useState({})
	const [success, setSuccess] = useState(false)
	const charCount = str => str.replace(/\s/g, '').length

	const limit = 300
	const count = charCount(value.message || '')
	const exceeded = count > limit

	const handleChange = e => {
		setValue({ ...value, [e.target.name]: e.target.value })
	}

	const handleClose = e => {
		e.preventDefault()
		hideModal()
	}

	const handleSubmit = e => {
		e.preventDefault()
		const form = e.target
		fetch('/', {
			method: 'POST',
			headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
			body: encode({
				'form-name': form.getAttribute('name'),
				...value,
			}),
		})
			.then(() => {
				form.reset()
				setSuccess(true)
			})
			.catch(error => alert(error))
	}

	return (
		<div className='w-full mx-auto my-auto md:pb-12 bg-primary rounded-none'>
			{!success ?
				<>
					<form
						name='contact'
						method='POST'
						action='/'
						data-netlify='true'
						data-netlify-honeypot='bot-field'
						onSubmit={handleSubmit}
						className='body flex flex-col md:h-auto md:border-b-4 min-h-[640px] md:min-h-0 2xl:border-b-8'>
						<h2 className='w-full h3 px-5 py-5 whitespace-nowrap border-b-4 md:px-16 2xl:border-b-8'>HEY NEW CULTURE</h2>
						<input type='hidden' name='form-name' value='contact' />
						<div className='flex flex-col px-5 flex-1 relative justify-evenly md:px-[7%] pt-[5%]'>
							<button
								className='absolute cursor-pointer z-50 right-5 -top-7'
								aria-label='Close Modal'
								onClick={handleClose}
							>
								<Close className='w-12' />
							</button>
							<div className='flex flex-col lg:flex-row lg:space-x-4'>
								<input
									type='text'
									name='firstName'
									autoComplete='off'
									onChange={handleChange}
									className='contact-field'
									placeholder='First Name'
									required
								/>
								<input
									type='text'
									name='lastName'
									autoComplete='off'
									onChange={handleChange}
									className='contact-field'
									placeholder='Last Name'
									required
								/>
								<input
									type='email'
									name='email'
									autoComplete='off'
									onChange={handleChange}
									className='px-[2%] py-1 md:py-2 bg-transparent border-2 focus-field lg:w-1/3'
									placeholder='Your Email'
									required
								/>
							</div>
							<div className='flex flex-col justify-center'>
								<span className='self-end'>
									{count}/{limit}
								</span>
								<textarea
									name='message'
									onChange={handleChange}
									className='p-[3%] bg-transparent border-2 focus-field '
									rows={isWide ? 7 : 4}
									placeholder='Leave us a note.'
									required
								/>
								{exceeded && (
									<span className='mt-2 body text-xs'>
										Max 300 characters allowed
									</span>
								)}
							</div>
							<div className='flex flex-col space-y-10 md:space-y-0 md:flex-row md:justify-between md:items-center my-5 md:max-w-[97%]'>
								<select
									name='category'
									required
									onBlur={handleChange}
									className='px-[2%] py-2 bg-transparent w-full border-2 md:w-3/5'
								>
									<option
										value=''
										defaultValue
									>
										Which best describes you?
									</option>
									{categories?.map((item, index) =>
										<option
											value={item}
											key={index}
										>
											{item}
										</option>
									)}
								</select>
								<button
									className='relative flex flex-col items-center self-center md:pt-2'
									type='submit'
								>
									<ContactButton className='absolute w-[180%] top-0 bottom-0 h-auto' />
									<div className='h3-alt'>Send</div>
								</button>
							</div>
						</div>
					</form>
				</> :
				<div className='h-full py-20 md:py-24'>
					<div className='relative flex w-full h-full justify-center items-center border-y-4 2xl:border-y-8'>
						<h4 className='h1-alt py-24'>Thank You!</h4>
						<button
							className='absolute cursor-pointer z-50 right-5 -top-7'
							aria-label='Close Modal'
							onClick={handleClose}
						>
							<Close className='w-12' />
						</button>
					</div>
				</div>
			}
		</div>
	);
}
